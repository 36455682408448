<template>
  <div class="card p-shadow-6" :style="{ position: 'relative' }">
    <h1>Suporte</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <Dialog
      id="'dialog-hours'"
      :visible.sync="showTaskForm"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="
        this.userTask != null && this.userTask.action === 'update'
          ? 'Atualizar Suporte'
          : this.userTask != null && this.userTask.action === 'view'
          ? 'Ver Suporte'
          : 'Criar Suporte'
      "
    >
      <SupportForm
        :task="userTask"
        :interventionTypes="interventionTypes"
        :myUsers="myUsers"
        v-on:submitTaskForm="taskFormSubmited"
        v-on:cancelTaskForm="taskFormCanceled"
      />
    </Dialog>

    <Dialog
      id="'calendarGoTo'"
      :visible.sync="calendarGoTo"
      :style="{ width: $isMobile() ? '100vw' : '35vw' }"
      :modal="true"
      :header="'Ir para dia'"
    >
      <div class="p-col-12 p-text-center">
        <v-calendar @dayclick="goDate" />
      </div>
    </Dialog>

    <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
    <Loading :active.sync="firstload" :is-full-page="false"></Loading>
    <Loading :active.sync="refreshLoad" :is-full-page="false"></Loading>

    <div class="p-fluid p-grid">
      <div class="p-col-12 p-md-3" v-for="node in nodes" :key="node.key">
        <Tree
          selectionMode="checkbox"
          :value="node"
          :selectionKeys.sync="selectedNodes"
          @node-select="onNodeSelect"
          @node-unselect="onNodeUnselect"
        >
          <template #default="slotProps">
            <div
              class="tree-border-color"
              :style="{
                'border-color':
                  slotProps.node.children != undefined
                    ? slotProps.node.children[0].officeColor
                    : slotProps.node.userColor,
              }"
            >
              {{ slotProps.node.label }}
              <i
                v-if="
                  slotProps.node.children != undefined &&
                  slotProps.node.children[0].officeColor
                "
                class="pi pi-info-circle"
                v-tooltip="
                  '<div style=\'display: inline-flex;\'><div class = \'square\' style=\'background-color:' +
                  getInterventionColor(
                    'Interno',
                    slotProps.node.children[0].officeColor
                  ) +
                  '\'></div>Interno</div><br/>' +
                  '<div style=\'display: inline-flex;\'> <div class = \'square\' style=\'background-color:' +
                  getInterventionColor(
                    'Assistência',
                    slotProps.node.children[0].officeColor
                  ) +
                  '\'></div>Assistência</div><br/>' +
                  '<div style=\'display: inline-flex;\'><div class = \'square\' style=\'background-color:' +
                  getInterventionColor(
                    'Instalação',
                    slotProps.node.children[0].officeColor
                  ) +
                  '\'></div>Instalação</div><br/>' +
                  '<div style=\'display: inline-flex;\'><div class = \'square\' style=\'background-color:' +
                  getInterventionColor(
                    'Survey',
                    slotProps.node.children[0].officeColor
                  ) +
                  '\'></div>Survey</div>'
                "
              >
              </i>
            </div>
          </template>
        </Tree>
      </div>
      <div class="p-col-12">
        <b>Cor das Intervenções</b>
        <div class="p-fluid p-grid p-mt-2 p-ml-1">
          <div class="p-d-flex p-ai-center">
            <InputSwitch
              v-model="taskColor.office"
              class="p-mr-2"
              @input="taskColorChange('office')"
            />
            <label>Escritório</label>
          </div>
          <div class="p-d-flex p-ai-center p-ml-4">
            <InputSwitch
              v-model="taskColor.task"
              class="p-mr-2"
              @input="taskColorChange('task')"
            />
            <label> Tipo </label>
          </div>
          <div class="p-d-flex p-ai-center p-ml-4">
            <InputSwitch
              v-model="taskColor.user"
              class="p-mr-2"
              @input="taskColorChange('user')"
            />
            <label>Colaborador</label>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <b>Ver</b>
        <div class="p-fluid p-grid p-mt-2 p-ml-1">
          <div class="p-d-flex p-ai-center">
            <InputSwitch
              v-model="taskTypes.all"
              class="p-mr-2"
              @input="taskTypeChange('all')"
            />
            <label>Tudo</label>
          </div>
          <div class="p-d-flex p-ai-center p-ml-4">
            <InputSwitch
              v-model="taskTypes.interventions"
              class="p-mr-2"
              @input="taskTypeChange('interventions')"
            />
            <label> Intervenções </label>
          </div>
          <div class="p-d-flex p-ai-center p-ml-4">
            <InputSwitch
              v-model="taskTypes.schedules"
              class="p-mr-2"
              @input="taskTypeChange('schedules')"
            />
            <label>Reservas</label>
          </div>
          <div class="p-d-flex p-ai-center p-ml-4">
            <InputSwitch
              v-model="taskTypes.unavailable"
              class="p-mr-2"
              @input="taskTypeChange('unavailable')"
            />
            <label>Indisponibilidades</label>
          </div>
        </div>
      </div>
    </div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div
          v-if="arg.event._def.extendedProps.isHoliday"
          class="position-fixed"
        >
          <div><b>Feriado:</b> {{ arg.event.title }}</div>
        </div>
        <div v-else-if="arg.event._def.extendedProps.inVacation">
          <div>
            <b>{{ arg.event._def.extendedProps.technicianInitials }}</b> -
            {{ arg.event.title }}
          </div>
        </div>
        <div v-else-if="arg.event._def.extendedProps.missed">
          <div>
            <b>{{ arg.event._def.extendedProps.technicianInitials }}</b> - Falta
          </div>
        </div>
        <div v-else>
          <div class="p-d-flex p-jc-between">
            <div
              v-if="arg.event._def.extendedProps.taskInfo.isBooking == true"
              v-tooltip="
                '<div>' +
                arg.timeText +
                '<br />' +
                '<b>' +
                (arg.event._def.extendedProps.taskInfo.type == 1
                  ? 'Reservado'
                  : 'Indisponível') +
                '</b><br />' +
                '<b>Nota: </b>' +
                arg.event._def.extendedProps.taskInfo.note +
                '<br />' +
                '<b>Técnico: </b>' +
                arg.event._def.extendedProps.taskInfo.technician +
                '</div>'
              "
            >
              <b>{{
                arg.event._def.extendedProps.taskInfo.technician_initials
              }}</b>
            </div>
            <div
              v-else
              v-tooltip="
                '<div>' +
                arg.timeText +
                '<br />' +
                '<b>Nota: </b>' +
                arg.event._def.extendedProps.taskInfo.note +
                '<br />' +
                '<b>Técnico: </b>' +
                arg.event._def.extendedProps.taskInfo.technician +
                '<br />' +
                '<b>PAT: </b>' +
                arg.event._def.extendedProps.taskInfo.pat_number +
                '<br />' +
                '<b>Título: </b>' +
                arg.event._def.extendedProps.taskInfo.pat_title +
                '<br />' +
                '<b>Cliente: </b>' +
                arg.event._def.extendedProps.taskInfo.entity_name +
                '<br />' +
                '<b>Morada: </b>' +
                (arg.event._def.extendedProps.taskInfo.localization.address !=
                undefined
                  ? arg.event._def.extendedProps.taskInfo.localization.address +
                    '<br />'
                  : '') +
                (arg.event._def.extendedProps.taskInfo.localization.zip_code !=
                undefined
                  ? arg.event._def.extendedProps.taskInfo.localization.zip_code
                  : '') +
                (arg.event._def.extendedProps.taskInfo.localization.local !=
                undefined
                  ? '-' +
                    arg.event._def.extendedProps.taskInfo.localization.local
                  : '') +
                '</div>'
              "
            >
              <b>{{
                arg.event._def.extendedProps.taskInfo.technician_initials
              }}</b>
            </div>
            <div>
              <i
                class="pi pi-trash p-mr-1"
                v-if="arg.event._def.extendedProps.canDelete"
                v-on:click="deleteTask(arg.event.id)"
                v-tooltip="'Eliminar tarefa'"
              ></i>
              <i
                class="pi pi-info-circle p-mr-1"
                v-if="arg.event._def.extendedProps.taskInfo.isBooking == true"
                v-tooltip="
                  '<div>' +
                  arg.timeText +
                  '<br />' +
                  '<b>' +
                  (arg.event._def.extendedProps.taskInfo.type == 1
                    ? 'Reservado'
                    : 'Indisponível') +
                  '</b><br />' +
                  '<b>Nota: </b>' +
                  arg.event._def.extendedProps.taskInfo.note +
                  '<br />' +
                  '<b>Técnico: </b>' +
                  arg.event._def.extendedProps.taskInfo.technician +
                  '</div>'
                "
              ></i>
              <i
                v-else
                class="pi pi-info-circle p-mr-1"
                v-tooltip="
                  '<div>' +
                  arg.timeText +
                  '<br />' +
                  '<b>Tipo de Serviço: </b>' +
                  arg.event._def.extendedProps.taskInfo.pat_type_name +
                  '<br />' +
                  '<b>Nota: </b>' +
                  arg.event._def.extendedProps.taskInfo.note +
                  '<br />' +
                  '<b>Técnico: </b>' +
                  arg.event._def.extendedProps.taskInfo.technician +
                  '<br />' +
                  '<b>PAT: </b>' +
                  arg.event._def.extendedProps.taskInfo.pat_number +
                  '<br />' +
                  '<b>Título: </b>' +
                  arg.event._def.extendedProps.taskInfo.pat_title +
                  '<br />' +
                  '<b>Cliente: </b>' +
                  arg.event._def.extendedProps.taskInfo.entity_name +
                  '<br />' +
                  '<b>Morada: </b>' +
                  (arg.event._def.extendedProps.taskInfo.localization.address !=
                  undefined
                    ? arg.event._def.extendedProps.taskInfo.localization
                        .address + '<br />'
                    : '') +
                  (arg.event._def.extendedProps.taskInfo.localization
                    .zip_code != undefined
                    ? arg.event._def.extendedProps.taskInfo.localization
                        .zip_code
                    : '') +
                  (arg.event._def.extendedProps.taskInfo.localization.local !=
                  undefined
                    ? '-' +
                      arg.event._def.extendedProps.taskInfo.localization.local
                    : '') +
                  '</div>'
                "
              ></i>
              <i
                class="pi pi-clone p-mr-1"
                v-if="
                  arg.isDraggable &&
                  (loggedUser.isEsa ||
                    arg.event._def.extendedProps.taskInfo.created_by ==
                      loggedUser.username ||
                    can('supportEditTasks'))
                "
                v-on:click="cloneTask(arg.event.id)"
                v-tooltip="'Clonar'"
              ></i>
              <i
                class="pi pi-pencil p-mr-1"
                v-if="
                  arg.isDraggable &&
                  (loggedUser.isEsa ||
                    arg.event._def.extendedProps.taskInfo.created_by ==
                      loggedUser.username ||
                    can('supportEditTasks'))
                "
                v-on:click="updateFromForm(arg.event.id)"
                v-tooltip="'Editar tarefa'"
              ></i>
              <i
                class="pi pi-eye p-mr-1"
                v-else
                v-on:click="updateFromForm(arg.event.id)"
                v-tooltip="'Ver tarefa'"
              ></i>
            </div>
          </div>
          <div>
            <div>{{ arg.timeText }}</div>
            <div v-if="arg.event._def.extendedProps.taskInfo.isBooking == true">
              <b v-if="arg.event._def.extendedProps.taskInfo.type == 1"
                >Reservado</b
              >
              <b v-if="arg.event._def.extendedProps.taskInfo.type == 2"
                >Indisponível</b
              >
            </div>
            <div
              v-else
              class="text-ellipsis"
              v-tooltip="arg.event._def.extendedProps.taskInfo.entity_name"
            >
              <b> {{ arg.event._def.extendedProps.taskInfo.entity_name }} </b>
            </div>
            <div
              v-if="arg.event._def.extendedProps.taskInfo.pat_type_name != ''"
              class="text-ellipsis"
            >
              <b>{{ arg.event._def.extendedProps.taskInfo.pat_type_name }}</b>
            </div>
            <div class="text-ellipsis">
              {{ arg.event._def.extendedProps.taskInfo.note }}
            </div>
          </div>
          <!-- <div>
            <b>Pat:</b> {{ arg.event._def.extendedProps.taskInfo.pat_number }}
          </div>
          <div v-if="arg.event._def.extendedProps.taskInfo.pat_title != ''">
            <b>Título: </b>
            {{ arg.event._def.extendedProps.taskInfo.pat_title }}
          </div> -->
        </div>
      </template>

      <template v-slot:resourceLabelContent="arg">
        <span
          v-if="arg.view.type == 'resourceTimeGrid'"
          :style="{ backgroundColor: arg.resource.extendedProps.officeColor }"
          class="resourceLabelContent"
        >
          {{ arg.resource.title }} - {{ arg.resource.extendedProps.office }}
        </span>
        <span
          v-else-if="arg.view.type == 'resourceTimeGridWeek'"
          :style="{ backgroundColor: arg.resource.extendedProps.officeColor }"
          class="resourceLabelContent"
          v-tooltip = arg.resource.title
        >
          {{ arg.resource.extendedProps.technicianInitials }}
        </span>
        <span v-else>
          {{ arg.resource.title }}
        </span>
      </template>
      <template v-slot:dayHeaderContent="arg">
        <span class="p-md-10 p-text-left">
          {{ arg.text }}
        </span>
        <span class="p-md-2 p-text-right">
          <i class="day-header-status pi pi-lock-open"></i>
        </span>
        <span class="p-md-2 p-text-right">
          <i
            class="day-header-status pi pi-map-marker cursor-grab"
            v-tooltip="'Ver no mapa'"
            v-on:click="goToMap(arg.date)"
          ></i>
        </span>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import supportForm from "./SupportForm";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timelinePlugin from "@fullcalendar/timeline";

import ptLocale from "@fullcalendar/core/locales/pt";
import {
  getOnlyDate,
  hexToRGB,
  getOnlyTime,
  isDateBeforeToday,
} from "../helpers/helpers";
import task from "../models/task";
import Task from "../models/task";
import taskService from "../services/task.service";
import interventionService from "../services/intervention.service";
import bookingService from "../services/booking.service";
import moment from "moment";
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Support",
  components: {
    SupportForm: supportForm,
    Loading: loading,
  },
  data() {
    return {
      taskColor: { office: true, task: false, user: false },
      taskTypes: {
        all: true,
        interventions: false,
        schedules: false,
        unavailable: false,
      },
      userTask: null,
      showTaskForm: false,
      userId: null,
      interventionTypes: [],
      calendarApi: null,
      tasksInCalendar: [],
      myUsers: [],
      calendarOptions: null,
      viewOtherUsers: false,
      calendarGoTo: false,
      isLoading: true,
      nodes: [],
      selectedNodes: {},
      userTasksInCalendar: {},
      firstload: true,
      refreshLoad: false,
      key: 0,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  beforeCreate() {},
  async created() {
    this.userId = this.$store.state.auth.user.id;
    await this.getInterventionTypes();
  },
  async beforeMount() {
    this.dataToTree();
    await this.generateCalendar();
    let updatateBtn = document.querySelector(".fc-icon-update-btn");
    if (updatateBtn) {
      updatateBtn.className = "pi pi-spinner"
    }
  },
  mounted() {
    this.calendarApi = this.$refs.fullCalendar.getApi();
    let currentTime = moment();
    this.calendarApi.scrollToTime({
      days: currentTime.isoWeekday() - 1,
      milliseconds:
        ((currentTime.hours() - 1) * 60 + currentTime.minutes()) * 60000,
    });
  },
  methods: {
    dataToTree() {
      let hasLocal = false;
      if (localStorage.selectedNodes != undefined) {
        hasLocal = true;
        this.selectedNodes = JSON.parse(localStorage.selectedNodes);
      }
      for (const [office, user] of Object.entries(
        this.$store.state.auth.user.supportUsers
      )) {
        let users = [];

        user.forEach((myUser) => {
          this.myUsers.push({
            id: myUser.id,
            username: myUser.username,
            office: office,
            userColor: myUser.userColor,
            officeColor: myUser.officeColor,
            technicianInitials: myUser.technicianInitials,
          });
          users.push({
            key: myUser.id,
            label: myUser.username,
            data: `${office} Office User`,
            icon: "pi pi-fw pi-user",
            userColor: myUser.userColor,
            officeColor: myUser.officeColor,
            technicianInitials: myUser.technicianInitials,
          });

          if (!hasLocal) {
            this.selectedNodes[myUser.id] = {
              checked: true,
              partialChecked: false,
            };
          }
          this.userTasksInCalendar[myUser.id] = {
            inCalendar:
              this.selectedNodes[myUser.id] != undefined
                ? this.selectedNodes[myUser.id].checked
                : false,
            userId: myUser.id,
            userColor: myUser.userColor,
            officeColor: myUser.officeColor,
            username: myUser.username,
            userOffice: myUser.office,
            technicianInitials: myUser.technicianInitials,
            tasks: [],
          };
        });

        let tree = {
          key: office,
          label: office,
          data: `office`,
          icon: "pi pi-fw pi-home",
          children: users,
        };
        this.nodes.push({ tree });
        if (!hasLocal) {
          this.selectedNodes[office] = {
            checked: true,
            partialChecked: false,
          };
        }
      }
      if (!hasLocal) {
        localStorage.selectedNodes = JSON.stringify(this.selectedNodes);
      }
    },
    refetchEvents() {
      this.refreshLoad = true;
      this.calendarApi.refetchEvents();
    },
    generateCalendar() {
      const calendarGoTo = () => (this.calendarGoTo = true);

      const changeView = (view) => {
        localStorage.supportInitialView = view;
        this.calendarApi.changeView(view);
      };
      const changeDates = (where) => {
        if (where == "prev") {
          this.calendarApi.prev();
        } else if (where == "next") {
          this.calendarApi.next();
        }
      };

      const toogleWeekends = () => {
        if (this.calendarOptions.weekends == false) {
          this.calendarOptions.weekends = true;
          localStorage.supportShowWeekends = true;
        } else {
          this.calendarOptions.weekends = false;
          localStorage.supportShowWeekends = false;
        }
      };

      const refetchEvents = () => {
        return this.refetchEvents();
      };

      const getResources = () => {
        return this.getResources();
      };
      const stripedDays = (arg) => {
        if (moment(arg.date).isoWeekday() % 2 == 0) {
          return "slotLaneStriped";
        }
      };

      this.calendarOptions = {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          resourceTimelinePlugin,
          timelinePlugin,
          resourceTimeGridPlugin,
        ],
        stickyHeaderDates: true,
        schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
        locale: ptLocale,
        allDaySlot: true,
        nowIndicator: true,
        selectable: true,
        firstDay: 1,
        height: 600,
        weekends: localStorage.supportShowWeekends
          ? localStorage.supportShowWeekends
          : false,
        scrollTime: "09:00",
        slotDuration: "00:15",
        slotLabelInterval: "01:00",
        views: {
          timeGrid: {
            slotLabelFormat: {
              hour: "numeric",
              minute: "2-digit",
              omitZeroMinute: false,
              meridiem: "short",
            },
          },
          timeline: {
            slotLaneClassNames: stripedDays,
            slotLabelFormat: [
              {},
              {
                hour: "numeric",
                minute: "2-digit",
                omitZeroMinute: false,
                meridiem: "short",
              },
            ],
          },
        },
        datesAboveResources: true,
        initialView: localStorage.supportInitialView
          ? localStorage.supportInitialView
          : this.$isMobile()
          ? "timeGrid"
          : "timeGridWeek",
        resources: getResources,
        resourceGroupField: "office",
        resourceOrder: "office",
        resourceAreaWidth: "15%",
        resourceAreaColumns: [
          {
            headerContent: "Equipa",
          },
        ],
        displayEventTime: true,
        displayEventEnd: true,
        editable: true,
        slotEventOverlap: false,
        customButtons: {
          showCalendar: {
            text: "Ir para",
            click: function () {
              calendarGoTo();
            },
          },
          toogleWeekends: {
            text: "Toggle Fds",
            hint: "Mostrar/Ocultar Fim de Semana",
            click: function () {
              toogleWeekends();
            },
          },
          updateCalendar: {
            icon: "update-btn",
            hint: "Atualizar Tarefas",
            click: function () {
              refetchEvents();
            },
          },
          prev: {
            icon: "chevron-left",
            click: function () {
              changeDates("prev");
            },
          },
          next: {
            icon: "chevron-right",
            click: function () {
              changeDates("next");
            },
          },
          resourceTimelineDay: {
            text: "Disponibilidade",
            click: function () {
              // changeView("resourceTimelineDay");
              changeView("resourceTimeGridWeek");
            },
          },
          resourceTimeGrid: {
            text: "Dia",
            click: function () {
              changeView("resourceTimeGrid");
            },
          },
          timeGridWeek: {
            text: "Semana",
            click: function () {
              changeView("timeGridWeek");
            },
          },
        },
        buttonText: {
          // resourceTimeGrid: "Dia",
          // resourceTimelineDay: "Disponibilidade",
        },
        headerToolbar: {
          left: "prev,today,showCalendar,next",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimeGrid,timeGridWeek,toogleWeekends,updateCalendar",
        },
      };
      const stopLoading = () => (this.refreshLoad = false);
      const getTasks = (dateStart, dateEnd, userId, officeColor) =>
        this.getUserTasks(dateStart, dateEnd, userId, officeColor);
      const removeAllTasks = () => this.removeAllTasks();
      const userInCalendar = this.userTasksInCalendar;
      let allTasks = [];
      let promises = [];
      this.calendarOptions.events = [];
      this.calendarOptions.events = function (info, successCallback) {
        removeAllTasks();
        allTasks = [];
        promises = [];
        Object.entries(userInCalendar).forEach(([userId, userInfo]) => {
          if (userInfo.inCalendar) {
            promises.push(
              getTasks(
                getOnlyDate(info.start),
                getOnlyDate(info.end),
                userId,
                userInfo.officeColor
              ).then((tasks) => {
                allTasks = allTasks.concat(tasks);
              })
            );
          }
        });
        Promise.all(promises).then(() => {
          successCallback(allTasks);
          stopLoading();
        });
      };

      const updateTask = (taskId, taskData) =>
        this.updateTask(taskId, taskData);

      const updateBooking = (bookingId, bookingData) =>
        this.updateBooking(bookingId, bookingData);

      this.calendarOptions.eventResize = function (info) {
        if (info.event.extendedProps.taskInfo.isBooking) {
          let bookingInfo = {
            start: getOnlyTime(info.event.start),
            end: getOnlyTime(info.event.end),
          };

          info.event.extendedProps.taskInfo.start = bookingInfo.start;
          info.event.extendedProps.taskInfo.end = bookingInfo.end;
          updateBooking(info.event.extendedProps.taskInfo.id, bookingInfo);
        } else {
          let taskInfo = {
            type: info.event.extendedProps.taskInfo.typeId,
            pat_number: info.event.extendedProps.taskInfo.pat_number,
            start: `${getOnlyDate(info.event.start)} ${getOnlyTime(
              info.event.start
            )}`,
            end: `${getOnlyDate(info.event.end)} ${getOnlyTime(
              info.event.end
            )}`,
            from_support: true,
          };
          info.event.extendedProps.taskInfo.start = taskInfo.start;
          info.event.extendedProps.taskInfo.end = taskInfo.end;
          updateTask(info.event.id, taskInfo);
        }
      };

      const validateDayLocked = (day) => {
        let dayLocked = this.dayAreLocked(day);
        if (dayLocked) {
          this.$toast.add({
            severity: "error",
            summary: "O dia está fechado",
            detail: "Não é permitida esta ação",
            life: 3000,
          });
        }
        return dayLocked;
      };
      this.calendarOptions.eventDrop = function (info) {
        if (validateDayLocked(getOnlyDate(info.event.start))) {
          info.revert();
          return;
        }
        if (info.event.extendedProps.taskInfo.isBooking) {
          let bookingInfo = {
            date: getOnlyDate(info.event.start),
            start: getOnlyTime(info.event.start),
            end: getOnlyTime(info.event.end),
          };

          info.event.extendedProps.taskInfo.start = bookingInfo.start;
          info.event.extendedProps.taskInfo.end = bookingInfo.end;
          info.event.extendedProps.taskInfo.date = bookingInfo.date;
          updateBooking(info.event.extendedProps.taskInfo.id, bookingInfo);
        } else {
          let taskInfo = {
            type: info.event.extendedProps.taskInfo.typeId,
            pat_number: info.event.extendedProps.taskInfo.pat_number,
            start: `${getOnlyDate(info.event.start)} ${getOnlyTime(
              info.event.start
            )}`,
            end: `${getOnlyDate(info.event.end)} ${getOnlyTime(
              info.event.end
            )}`,
            from_support: true,
          };
          info.event.extendedProps.taskInfo.start = taskInfo.start;
          info.event.extendedProps.taskInfo.end = taskInfo.end;
          updateTask(info.event.id, taskInfo);
        }
      };

      const addTask = (start, end, userId) =>
        this.createTask(start, end, userId);

      this.calendarOptions.select = function (info) {
        let userId;
        if (info.resource != undefined) {
          userId = info.resource.id;
        }
        addTask(info.start, info.end, userId);
      };

      const updateTaskForm = (taskId) => this.updateFromForm(taskId);
      this.calendarOptions.eventDidMount = function (eventInfo) {
        if (eventInfo.event.startEditable == false) {
          return;
        }
        eventInfo.el.id = eventInfo.event.id;
        eventInfo.el.ondblclick = function () {
          updateTaskForm(eventInfo.event.id);
        };
      };
    },
    getInterventionTypes() {
      let typesCount = 0;
      return interventionService
        .getInterventionTypes()
        .then((response) => {
          return (this.interventionTypes = response);
        })
        .then((typeList) => {
          typesCount = typeList.length;
          typeList.forEach((type) => {
            this.isLoading = true;
            interventionService
              .getInterventionsByType(type.id)
              .then((typeProjects) => {
                type["projects"] = typeProjects;
                if (--typesCount <= 0) {
                  this.isLoading = false;
                  this.firstload = false;
                }
              });
          });
        });
    },
    getInterventionTypeId(interventionName) {
      let type = this.interventionTypes.find(
        (x) => x.name === interventionName
      );

      if (type == undefined) {
        return 0;
      }
      return type.id;
    },
    getInterventionColor(patType, officeColor) {
      if (patType === "Interno") {
        return hexToRGB(officeColor, 0.1);
      }
      if (patType === "Assistência") {
        return hexToRGB(officeColor, 0.3);
      }
      if (patType === "Instalação") {
        return hexToRGB(officeColor, 0.7);
      }
      if (patType === "Survey") {
        return hexToRGB(officeColor, 1);
      }
      return "#485B60";
    },
    getUserTasks(dateStart, dateEnd, userId, officeColor) {
      this.isLoading = true;
      let userTasks = [];
      return taskService
        .getTasksAndBookings(userId, dateStart, dateEnd)
        .then((days) => {
          this.tasksInCalendar = days;
          days.forEach((day) => {
            let isPast = isDateBeforeToday(day.day);
            if (isPast || !this.userPermissiondEdit()) {
              this.lockDay(day.day);
            }
            if (day.isHoliday || day.inVacation || day.missed) {
              let backgroundTask = {
                resourceId: userId,
                resourceEditable: false,
                editable: false,
                start: `${day.day} 00:00`,
                end: `${day.day} 23:59`,
                isHoliday: false,
                inVacation: false,
                missed: false,
                username: this.userTasksInCalendar[userId].username,
                textColor: "#43464f",
                technicianInitials:
                  this.userTasksInCalendar[userId].technicianInitials,
                userId: userId,
              };
              if (day.isHoliday) {
                backgroundTask.display = "background";
                backgroundTask.title = day.isHoliday;
                backgroundTask.isHoliday = true;
                backgroundTask.color = hexToRGB("#ff4910", 0.15);
              } else if (day.inVacation) {
                backgroundTask.allDay = true;
                backgroundTask.title = "Férias";
                backgroundTask.inVacation = true;
                backgroundTask.color = isPast
                  ? hexToRGB(officeColor, 0.4)
                  : officeColor;
                backgroundTask.editable = false;
              } else if (day.missed) {
                backgroundTask.allDay = true;
                backgroundTask.title = day.missed;
                backgroundTask.missed = true;
                backgroundTask.color = isPast
                  ? hexToRGB(officeColor, 0.4)
                  : officeColor;
                backgroundTask.editable = false;
              }
              userTasks.push(backgroundTask);
            }

            day.tasks.forEach((task) => {
              if (this.taskTypes.interventions && task.isBooking != undefined) {
                return;
              } else if (
                task.isBooking != undefined &&
                task.isBooking == true
              ) {
                if (this.taskTypes.schedules && task.type == 2) {
                  return;
                }
                if (this.taskTypes.unavailable && task.type == 1) {
                  return;
                }
              } else if (
                (this.taskTypes.unavailable || this.taskTypes.schedules) &&
                task.isBooking == undefined
              ) {
                return;
              }
              task.typeId = this.getInterventionTypeId(task.type);
              let color = officeColor;
              if (this.taskColor.task === true) {
                color = this.getInterventionColor(
                  task.pat_type_name,
                  officeColor
                );
              } else if (this.taskColor.user === true) {
                color = this.userTasksInCalendar[userId].userColor;
              }
              userTasks.push({
                resourceId: userId,
                resourceEditable: false,
                id:
                  task.isBooking != undefined && task.isBooking == true
                    ? `booking-${task.id}`
                    : task.id,
                title:
                  task.isBooking != undefined && task.isBooking == true
                    ? task.type == 1
                      ? `Reservado`
                      : `Indisponível`
                    : `${task.pat_number} - ${task.pat_title}`,
                start:
                  task.isBooking != undefined && task.isBooking == true
                    ? `${task.date} ${task.start}`
                    : task.start,
                end:
                  task.isBooking != undefined && task.isBooking == true
                    ? `${task.date} ${task.end}`
                    : task.end,
                color:
                  task.isBooking != undefined &&
                  task.isBooking &&
                  task.type == 2
                    ? hexToRGB(color, 0.4)
                    : color,
                editable: this.isEditable(task) && !isPast, //!isPast && this.userPermissiondEdit(),
                canDelete: isPast ? false : this.canDelete(task),
                taskInfo: task,
                userId: userId,
                username: this.userTasksInCalendar[userId].username,
                textColor: "#43464f",
                technicianInitials:
                  this.userTasksInCalendar[userId].technicianInitials,
              });
            });
            // FIM DAS TAREFAS DO DIA/USER
          });

          this.isLoading = false;
          return userTasks;
        });
    },
    getAvailable(dayTasks, day, userInfo) {
      const formatDate = (dateTime) => {
        return moment(dateTime).format("YYYY-MM-DD HH:mm");
      };
      dayTasks.sort((a, b) => {
        var timeA = formatDate(a.start);
        var timeB = formatDate(b.start);
        if (timeA < timeB) {
          return -1;
        }
        if (timeA > timeB) {
          return 1;
        }
        return 0;
      });

      let availableTimeArray = [];
      let endTime = moment(moment(day).format("YYYY-MM-DD 00:00"));
      let statTime = moment(moment(day).format("YYYY-MM-DD 23:59"));

      dayTasks.forEach((element, index) => {
        let currentEndTime = moment(formatDate(element.end));
        var currentStartTime = moment(formatDate(element.start));
        if (currentStartTime.isBefore(statTime)) {
          statTime = currentStartTime;
        }
        if (currentEndTime.isAfter(endTime)) {
          endTime = currentEndTime;
        }

        if (index === dayTasks.length - 1) {
          if (dayTasks.length === 1) {
            availableTimeArray.push({
              start: moment(day).format("YYYY-MM-DD 00:00"),
              end: currentStartTime.format("YYYY-MM-DD HH:mm"),
              userId: userInfo.userId,
              username: userInfo.username,
              textColor: userInfo.textColor,
              color: userInfo.color,
              availability: true,
              technicianInitials: userInfo.technicianInitials,
              title: userInfo.technicianInitials,
              editable: false,
            });
          }
          availableTimeArray.push({
            start: moment(endTime).format("YYYY-MM-DD HH:mm"),
            end: moment(day).format("YYYY-MM-DD 23:59"),
            userId: userInfo.userId,
            username: userInfo.username,
            textColor: userInfo.textColor,
            color: userInfo.color,
            availability: true,
            technicianInitials: userInfo.technicianInitials,
            title: userInfo.technicianInitials,
            editable: false,
          });
        } else {
          const nextBusyTime = dayTasks[index + 1];
          const nextStartTime = moment(formatDate(nextBusyTime.start));
          if (index === 0) {
            availableTimeArray.push({
              start: moment(day).format("YYYY-MM-DD 00:00"),
              end: currentStartTime.format("YYYY-MM-DD HH:mm"),
              userId: userInfo.userId,
              username: userInfo.username,
              textColor: userInfo.textColor,
              color: userInfo.color,
              availability: true,
              technicianInitials: userInfo.technicianInitials,
              title: userInfo.technicianInitials,
              editable: false,
            });
          }
          let endTimeToCompare = currentEndTime.isBefore(endTime)
            ? endTime
            : currentEndTime;
          if (endTimeToCompare.isBefore(nextStartTime)) {
            availableTimeArray.push({
              start: endTimeToCompare.format("YYYY-MM-DD HH:mm"),
              end: nextStartTime.format("YYYY-MM-DD HH:mm"),
              userId: userInfo.userId,
              username: userInfo.username,
              textColor: userInfo.textColor,
              color: userInfo.color,
              availability: true,
              technicianInitials: userInfo.technicianInitials,
              title: userInfo.technicianInitials,
              editable: false,
            });
          }
        }
      });
      if (availableTimeArray.length == 0) {
        availableTimeArray.push({
          start: moment(day).format("YYYY-MM-DD 00:00"),
          end: moment(day).format("YYYY-MM-DD 23:59"),
          userId: userInfo.userId,
          username: userInfo.username,
          textColor: userInfo.textColor,
          color: userInfo.color,
          availability: true,
          technicianInitials: userInfo.technicianInitials,
          title: userInfo.technicianInitials,
          editable: false,
        });
        return availableTimeArray;
      }
      let newAvailableTimeArray = availableTimeArray.filter((item) => {
        let duration = moment.duration(
          moment(item.end).diff(moment(item.start))
        );
        let minutes = duration.asMinutes();

        return minutes > 5;
      });
      return newAvailableTimeArray;
    },
    updateTask(taskId, taskNewInfo) {
      return taskService.updatedTask(taskId, taskNewInfo).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao gravar",
            detail: "Ocorreu um erro ao gravar a tarefa",
            life: 3000,
          });
        }
        return this.$toast.add({
          severity: "success",
          summary: "Atualizado com sucesso",
          detail: "A tarefa foi atualizada com sucesso",
          life: 3000,
        });
      });
    },
    deleteTask(itemId) {
      let item = this.calendarApi.getEventById(itemId);
      let itemInfo = item._def.extendedProps.taskInfo;
      this.$confirm.require({
        message: `Tem a certeza que deseja eliminar esta ${
          itemInfo.isBooking != undefined && itemInfo.isBooking
            ? itemInfo.type == 1
              ? "reserva"
              : "indisponíbilidade"
            : "tarefa"
        }?`,
        icon: "pi pi-exclamation-triangle",
        position: "top",
        accept: () => {
          if (itemInfo.isBooking != undefined && itemInfo.isBooking) {
            return bookingService
              .removeBooking(itemInfo.id)
              .then((response) => {
                if (!response) {
                  return this.$toast.add({
                    severity: "error",
                    summary: `Erro ao eliminar a ${
                      itemInfo.type == 1 ? "reserva" : "indisponíbilidade"
                    }`,
                    detail: `Ocorreu um erro ao eliminar a ${
                      itemInfo.type == 1 ? "reserva" : "indisponíbilidade"
                    }`,
                    life: 3000,
                  });
                }
                item.remove();
                this.$toast.add({
                  severity: "success",
                  summary: `${
                    itemInfo.type == 1 ? "Reserva" : "Indisponíbilidade"
                  } eliminada com sucesso`,
                  detail: "",
                  life: 3000,
                });
              });
          } else {
            let typeId = itemInfo.typeId;

            return taskService.removeTask(itemId, typeId).then((response) => {
              if (!response) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao eliminar a tarefa",
                  detail: "Ocorreu um erro ao eliminar a tarefa",
                  life: 3000,
                });
              }
              item.remove();
              this.$toast.add({
                severity: "success",
                summary: "Tarefa eliminada com sucesso",
                detail: "",
                life: 3000,
              });
            });
          }
        },
      });
    },
    createTask(start, end, userId) {
      this.calendarApi.unselect();
      let startDay = getOnlyDate(start);

      if (!this.userPermissiondEdit()) {
        return this.$toast.add({
          severity: "error",
          summary: "Sem permissão",
          detail: "Não tem permissão para criar tarefas de suporte",
          life: 3000,
        });
      }

      if (this.dayAreLocked(startDay)) {
        return this.$toast.add({
          severity: "error",
          summary: "Dia fechado",
          detail: "Não é possível a criação da tarefa",
          life: 3000,
        });
      }
      let endDay = getOnlyDate(end);

      if (startDay !== endDay) {
        return this.$toast.add({
          severity: "error",
          summary: "Data inválida",
          detail: "O dia de ínicio é diferente do dia de fim da tarefa",
          life: 3000,
        });
      }

      this.userTask = new task();
      this.userTask.action = "create";
      this.userTask.day = startDay;
      this.userTask.start = start;
      this.userTask.end = end;
      this.userTask.isBooking = false;
      this.userTask.type = null;
      this.userTask.taskType = 0;
      if (userId != undefined) {
        this.userTask.usersSelecteds = [
          {
            id: this.userTasksInCalendar[userId].userId,
            username: this.userTasksInCalendar[userId].username,
            office: this.userTasksInCalendar[userId].userOffice,
            technicianInitials:
              this.userTasksInCalendar[userId].technicianInitials,
            officeColor: this.userTasksInCalendar[userId].officeColor,
            userColor: this.userTasksInCalendar[userId].userColor,
          },
        ];
      }
      this.showTaskForm = true;
    },
    taskFormSubmited(task) {
      this.userTasks = null;
      this.showTaskForm = false;
      if (task.day instanceof Date) {
        task.day = getOnlyDate(task.day);
      }
      if (task.start instanceof Date) {
        task.start = getOnlyTime(task.start);
      }
      if (task.end instanceof Date) {
        task.end = getOnlyTime(task.end);
      }
      if (task.isBooking != undefined && task.isBooking == true) {
        //IS BOOKING
        let bookingInfo = {
          date: task.day,
          start: task.start,
          end: task.end,
          resume: task.resume != null ? task.resume : "",
          description: task.description != null ? task.description : "",
          usersSelecteds: task.usersSelecteds,
          note: task.note != null ? task.note : "",
          type: task.type != null ? task.type : 0,
        };

        if (task.oldUser != undefined && task.oldUser != task.usersSelecteds) {
          bookingInfo["new_user"] = task.usersSelecteds;
        }

        if (task.bookingId == undefined) {
          //NEW BOOKING
          return this.addBooking(bookingInfo);
        }
        //UPDATE Booking
        return this.updateBooking(task.bookingId, bookingInfo, true);
      } else if (
        task.isBooking != undefined &&
        task.isBooking == false &&
        task.bookingId != undefined
      ) {
        let taskInfo = {
          type: task.type,
          pat_number: task.patNumber,
          start: `${task.day} ${task.start}`,
          end: `${task.day} ${task.end}`,
          resume: task.resume,
          description: task.description,
          user_id: task.usersSelecteds,
          note: task.note,
          from_support: true,
        };
        //convert booking to task
        return this.createTaskFromBooking(task.bookingId, taskInfo);
      } else {
        //Is Task
        let taskInfo = {
          type: task.type,
          pat_number: task.patNumber,
          start: `${task.day} ${task.start}`,
          end: `${task.day} ${task.end}`,
          resume: task.resume != undefined ? task.resume : "",
          description: task.description != undefined ? task.description : "",
          usersSelecteds: task.usersSelecteds,
          note: task.note != undefined ? task.note : "",
          from_support: true,
        };
        if (task.oldUser != undefined && task.oldUser != task.usersSelecteds) {
          taskInfo["new_user"] = task.usersSelecteds;
        }
        switch (task.action) {
          case "update":
            return this.updateFormSubmited(task.taskId, taskInfo);
          default:
            this.saveNewTask(taskInfo);
        }
      }
    },
    taskFormCanceled() {
      this.userTasks = null;
      this.showTaskForm = false;
    },
    async saveNewTask(task) {
      const createNewTask = (user) => {
        task.user_id = user.id;
        return taskService
          .createTask(task)
          .then((response) => {
            if (response) {
              if (response.status == 409) {
                return response;
              }
              if (
                this.userTasksInCalendar[user.id] != undefined &&
                this.userTasksInCalendar[user.id].inCalendar == true
              ) {
                response.typeId = this.getInterventionTypeId(response.type);
                this.calendarApi.addEvent({
                  resourceId: user.id,
                  resourceEditable: false,
                  id: response.id,
                  title: `${response.pat_number} - ${response.pat_title}`,
                  start: response.start,
                  end: response.end,
                  color: this.userTasksInCalendar[user.id].officeColor,
                  editable: this.isEditable(response),
                  canDelete: this.canDelete(response),
                  textColor: "#43464f",
                  taskInfo: response,
                  userId: task.user_id,
                  username: this.userTasksInCalendar[task.user_id].username,
                });
              }
              return true;
            }

            return false;
          })
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro ao gravar",
                detail: `Ocorreu um erro ao gravar a tarefa do ${user.username}`,
                life: 3000,
              });
            }

            if (response.status != undefined && response.status == 409) {
              return this.$toast.add({
                severity: "error",
                summary: `Dia fechado`,
                detail: `O ${user.username} tem o dia fechado. Tarefa não criada`,
                life: 5000,
              });
            }

            return this.$toast.add({
              severity: "success",
              summary: `Tarefa criada`,
              detail: `Tarefa criada com sucesso para o ${user.username}`,
              life: 3000,
            });
          });
      };
      for (const userSelect of task.usersSelecteds) {
        await createNewTask(userSelect);
      }
    },
    updateFromForm(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let taskInfo = task._def.extendedProps.taskInfo;
      this.userTask = new Task();

      if (taskInfo.isBooking != undefined && taskInfo.isBooking == true) {
        this.userTask.isBooking = true;
        this.userTask.type = taskInfo.type;
        this.userTask.bookingId = taskInfo.id;
        this.userTask.day = taskInfo.date;
        this.userTask.start = new Date(`${taskInfo.date} ${taskInfo.start}`);
        this.userTask.end = new Date(`${taskInfo.date} ${taskInfo.end}`);
        this.userTask.type = taskInfo.type;
        this.userTask.taskType = taskInfo.type;
      } else {
        taskInfo.start = new Date(taskInfo.start);
        taskInfo.end = new Date(taskInfo.end);
        this.userTask.start = taskInfo.start;
        this.userTask.end = taskInfo.end;
        this.userTask.day = getOnlyDate(taskInfo.start);
        this.userTask.isBooking = false;
        this.userTask.localization = taskInfo.localization;
        this.userTask.type = taskInfo.typeId = !undefined ? taskInfo.typeId : 0;
        this.userTask.taskType = 0;
      }

      if (this.dayAreLocked(this.userTask.day) || !this.userPermissiondEdit()) {
        this.userTask.action = "view";
      } else if (
        !this.loggedUser.isEsa &&
        taskInfo.created_by != this.loggedUser.username &&
        !this.can("supportEditTasks")
      ) {
        this.userTask.action = "view";
      } else {
        this.userTask.action = "update";
      }
      // this.userTask.action =
      //   this.dayAreLocked(this.userTask.day) || !this.userPermissiondEdit()
      //     ? "view"
      //     : "update";
      this.userTask.resume = taskInfo.resume;
      this.userTask.description = taskInfo.description;
      this.userTask.patNumber = taskInfo.pat_number;
      this.userTask.taskId = taskId;
      this.userTask.color = taskInfo.color;
      this.userTask.technician = taskInfo.technician;
      this.userTask.created_by = taskInfo.created_by;
      this.userTask.created_at = `dia ${getOnlyDate(
        new Date(taskInfo.created_at)
      )} às ${getOnlyTime(new Date(taskInfo.created_at))}`;
      this.userTask.updated_by = taskInfo.updated_by;
      this.userTask.updated_at = `dia ${getOnlyDate(
        new Date(taskInfo.updated_at)
      )} às ${getOnlyTime(new Date(taskInfo.updated_at))}`;
      this.userTask.note = taskInfo.note;
      this.userTask.usersSelecteds = parseInt(task._def.extendedProps.userId);
      this.userTask.oldUser = parseInt(task._def.extendedProps.userId);
      this.userTask.task_all_info = taskInfo;

      this.showTaskForm = true;
    },
    updateFormSubmited(taskId, taskNewInfo) {
      return taskService.updatedTask(taskId, taskNewInfo).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao gravar",
            detail: "Ocorreu um erro ao gravar a tarefa",
            life: 3000,
          });
        }
        let task = this.calendarApi.getEventById(taskId);
        task.remove();
        response.typeId = this.getInterventionTypeId(response.type);
        if (
          this.userTasksInCalendar[taskNewInfo.usersSelecteds] != undefined &&
          this.userTasksInCalendar[taskNewInfo.usersSelecteds].inCalendar ==
            true
        ) {
          this.calendarApi.addEvent({
            resourceId: taskNewInfo.usersSelecteds,
            resourceEditable: false,
            id: response.id,
            title: `${response.pat_number} - ${response.pat_title}`,
            start: response.start,
            end: response.end,
            color:
              this.userTasksInCalendar[taskNewInfo.usersSelecteds].officeColor,
            editable: this.isEditable(response),
            canDelete: this.canDelete(response),
            taskInfo: response,
            userId: taskNewInfo.usersSelecteds,
            username:
              this.userTasksInCalendar[taskNewInfo.usersSelecteds].username,
            textColor: "#43464f",
          });
        }
        return this.$toast.add({
          severity: "success",
          summary: "Tarefa atualizada com sucesso",
          detail: "",
          life: 3000,
        });
      });
    },
    lockDay(day) {
      let dateHeader = document.querySelector(`[data-date="${day}"]`);
      if (dateHeader) {
        let icone = dateHeader.querySelector("i");
        if (icone) {
          icone.classList.remove("pi-lock-open");
          icone.classList.add("pi-lock");
        }
      }
    },
    dayAreLocked(day) {
      if (day instanceof Date) {
        day = getOnlyDate(day);
      }
      return isDateBeforeToday(day);
    },
    permissionUnLocked(day) {
      //Case loged user is ESA
      if (this.$store.state.auth.user.isEsa) {
        return true;
      }

      //Case loged user not permission to change selected user
      if (!this.getSelectedUser().permissionChangeMe) {
        return false;
      }

      let dayAux = this.tasksInCalendar.find((x) => x.day === day);
      if (dayAux.currentStatus === undefined && dayAux.locked) {
        return false;
      }
      return true;
    },
    forbiddenToast() {
      return this.$toast.add({
        severity: "error",
        summary: "Não autorizado",
        detail: "Não tem permissão para efetuar esta ação",
        life: 3000,
      });
    },
    goDate(date) {
      this.calendarApi.gotoDate(date.id);
      this.calendarGoTo = false;
    },
    removeAllTasks() {
      if (!this.calendarApi) {
        return;
      }
      let events = this.calendarApi.getEvents();
      events.forEach((event) => {
        event.remove();
      });
    },
    onNodeSelect(node) {
      localStorage.selectedNodes = JSON.stringify(this.selectedNodes);
      const userTask = (startDate, endDate, user, officeColor) => {
        this.getUserTasks(startDate, endDate, user, officeColor).then(
          (tasks) => {
            tasks.forEach((task) => {
              this.calendarApi.addEvent(task);
            });
          }
        );
      };
      if (node.data == "office") {
        this.$store.state.auth.user.supportUsers[node.key].forEach((user) => {
          if (
            this.userTasksInCalendar[user.id] == undefined ||
            this.userTasksInCalendar[user.id].inCalendar == false
          ) {
            this.userTasksInCalendar[user.id] = {
              inCalendar: true,
              userId: user.id,
              userColor: user.userColor,
              officeColor: user.officeColor,
              username: user.username,
              userOffice: user.office,
              technicianInitials: user.technicianInitials,
            };

            userTask(
              getOnlyDate(this.calendarApi.view.currentStart),
              getOnlyDate(this.calendarApi.view.currentEnd),
              user.id,
              user.officeColor
            );
          }
        });
      } else if (
        this.userTasksInCalendar[node.key] == undefined ||
        this.userTasksInCalendar[node.key].inCalendar == false
      ) {
        this.userTasksInCalendar[node.key] = {
          inCalendar: true,
          userId: node.key,
          userColor: node.userColor,
          officeColor: node.officeColor,
          username: node.label,
          technicianInitials: node.technicianInitials,
        };

        userTask(
          getOnlyDate(this.calendarApi.view.currentStart),
          getOnlyDate(this.calendarApi.view.currentEnd),
          node.key,
          node.officeColor
        );
      }
      this.calendarApi.refetchResources();
    },
    onNodeUnselect(node) {
      localStorage.selectedNodes = JSON.stringify(this.selectedNodes);
      const removeTasks = (userId) => {
        this.userTasksInCalendar[userId].inCalendar = false;
        let events = this.calendarApi.getEvents();
        events.forEach((event) => {
          if (event.extendedProps.userId == userId) {
            event.remove();
          }
        });
      };

      if (node.data == "office") {
        this.$store.state.auth.user.supportUsers[node.key].forEach((user) => {
          removeTasks(user.id);
        });
      } else {
        removeTasks(node.key);
      }
      this.calendarApi.refetchResources();
    },
    updateBooking(bookingId, bookingNewInfo, fromForm) {
      return bookingService
        .updatedBooking(bookingId, bookingNewInfo)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar a reserva",
              life: 3000,
            });
          }

          if (fromForm != undefined && fromForm == true) {
            let booking = this.calendarApi.getEventById(`booking-${bookingId}`);
            booking.remove();
            this.calendarApi.addEvent({
              resourceId: bookingNewInfo.usersSelecteds,
              resourceEditable: false,
              id: `booking-${response.id}`,
              title: response.type == 1 ? `Reservado` : `Indisponível`,
              start: `${response.date} ${response.start}`,
              end: `${response.date} ${response.end}`,
              color:
                response.type == 2
                  ? hexToRGB(
                      this.userTasksInCalendar[bookingNewInfo.usersSelecteds]
                        .officeColor,
                      0.4
                    )
                  : this.userTasksInCalendar[bookingNewInfo.usersSelecteds]
                      .officeColor,
              editable: this.isEditable(response),
              canDelete: this.canDelete(response),
              textColor: "#43464f",
              taskInfo: response,
              userId: bookingNewInfo.usersSelecteds,
              username:
                this.userTasksInCalendar[bookingNewInfo.usersSelecteds]
                  .username,
            });
          }
          return this.$toast.add({
            severity: "success",
            summary: "Atualizado com sucesso",
            detail: "A reserva foi atualizada com sucesso",
            life: 3000,
          });
        });
    },
    addBooking(booking) {
      booking.usersSelecteds.forEach((user) => {
        booking.user_id = user.id;
        bookingService
          .createBooking(booking)
          .then((response) => {
            if (response) {
              if (
                this.userTasksInCalendar[user.id] != undefined &&
                this.userTasksInCalendar[user.id].inCalendar == true
              ) {
                response.typeId = this.getInterventionTypeId(response.type);
                this.calendarApi.addEvent({
                  resourceId: user.id,
                  resourceEditable: false,
                  id: `booking-${response.id}`,
                  title: response.type == 1 ? `Reservado` : `Indisponível`,
                  type: response.type,
                  start: `${response.date} ${response.start}`,
                  end: `${response.date} ${response.end}`,
                  color:
                    response.type == 2
                      ? hexToRGB(
                          this.userTasksInCalendar[user.id].officeColor,
                          0.4
                        )
                      : this.userTasksInCalendar[user.id].officeColor,
                  editable: this.isEditable(response),
                  canDelete: this.canDelete(response),
                  textColor: "#43464f",
                  taskInfo: response,
                  userId: booking.user_id,
                  username: this.userTasksInCalendar[booking.user_id].username,
                });
              }
              return true;
            }
            return false;
          })
          .then((response) => {
            if (!response) {
              return this.$toast.add({
                severity: "error",
                summary: "Erro ao gravar",
                detail: `Ocorreu um erro ao gravar a ${
                  booking.type == 1 ? "reserva" : "indisponibilidade"
                }`,
                life: 3000,
              });
            }

            return this.$toast.add({
              severity: "success",
              summary: `${
                booking.type == 1 ? "Reserva" : "Indisponibilidade"
              } criada com sucesso`,
              detail: "",
              life: 3000,
            });
          });
      });
    },
    createTaskFromBooking(bookingId, task) {
      taskService
        .createTask(task)
        .then((response) => {
          if (response) {
            let booking = this.calendarApi.getEventById(`booking-${bookingId}`);
            booking.remove();
            if (
              this.userTasksInCalendar[task.user_id] != undefined &&
              this.userTasksInCalendar[task.user_id].inCalendar == true
            ) {
              response.typeId = this.getInterventionTypeId(response.type);
              this.calendarApi.addEvent({
                resourceId: task.user_id,
                resourceEditable: false,
                id: response.id,
                title: `${response.pat_number} - ${response.pat_title}`,
                start: response.start,
                end: response.end,
                color: this.userTasksInCalendar[task.user_id].officeColor,
                editable: this.isEditable(response),
                canDelete: this.canDelete(response),
                textColor: "#43464f",
                taskInfo: response,
                userId: task.user_id,
                username: this.userTasksInCalendar[task.user_id].username,
              });
            }
            return response.id;
          }
          return false;
        })
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar a tarefa",
              life: 3000,
            });
          }

          bookingService.updatedBooking(bookingId, {
            intervention_id: response,
          });
          return this.$toast.add({
            severity: "success",
            summary: "Tarefa criada com sucesso",
            detail: "",
            life: 3000,
          });
        });
    },
    userPermissiondEdit() {
      if (this.$store.state.auth.user.isEsa) {
        return true;
      }
      if (
        this.$store.state.auth.user.department == "Tecnico" ||
        this.$store.state.auth.user.department == "Tecnico - Admin"
      ) {
        return true;
      }

      return false;
    },
    goToMap(date) {
      let route = this.$router.resolve({
        path: `/support-map/${getOnlyDate(date)}`,
      });
      window.open(route.href, "_blank");
    },
    async getResources() {
      const setResource = (userInfo, officeInfo) => {
        return new Promise((resolve) =>
          resolve({
            id: userInfo.key,
            office: officeInfo.label,
            title: userInfo.label,
            officeColor: userInfo.officeColor,
            userColor: userInfo.userColor,
            technicianInitials: userInfo.technicianInitials,
          })
        );
      };
      let resouces = [];
      for (const office of this.nodes) {
        for (const officeUser of office.tree.children) {
          if (this.userTasksInCalendar[officeUser.key].inCalendar) {
            resouces.push(await setResource(officeUser, office.tree));
          }
        }
      }

      return resouces;
    },
    taskColorChange(by) {
      if (by == "task") {
        this.taskColor = {
          office: false,
          task: true,
          user: false,
        };
      } else if (by == "user") {
        this.taskColor = {
          office: false,
          task: false,
          user: true,
        };
      } else {
        this.taskColor = {
          office: true,
          task: false,
          user: false,
        };
      }
      return this.refetchEvents();
    },
    taskTypeChange(by) {
      if (by == "unavailable") {
        this.taskTypes = {
          all: false,
          interventions: false,
          schedules: false,
          unavailable: true,
        };
      } else if (by == "schedules") {
        this.taskTypes = {
          all: false,
          interventions: false,
          schedules: true,
          unavailable: false,
        };
      } else if (by == "interventions") {
        this.taskTypes = {
          all: false,
          interventions: true,
          schedules: false,
          unavailable: false,
        };
      } else {
        this.taskTypes = {
          all: true,
          interventions: false,
          schedules: false,
          unavailable: false,
        };
      }
      return this.refetchEvents();
    },
    isEditable(task) {
      if (this.loggedUser.isEsa) {
        return true;
      }
      //Caso de indisponibilidade
      if (
        task.isBooking != undefined &&
        task.isBooking == true &&
        task.type == 2
      ) {
        if (this.can("editUnavailability")) {
          return true;
        }
        return false;
      }
      if (
        task.created_by != this.loggedUser.username &&
        !this.can("supportEditTasks")
      ) {
        return false;
      }
      return true;
    },
    canDelete(task) {
      if (this.loggedUser.isEsa) {
        return true;
      }
      //Caso de indisponibilidade
      if (
        task.isBooking != undefined &&
        task.isBooking == true &&
        task.type == 2
      ) {
        if (this.can("deleteUnavailability")) {
          return true;
        }
        return false;
      }
      if (task.created_by == this.loggedUser.username) {
        return true;
      }
      if (this.can("deleteTasks")) {
        return true;
      }
      return false;
    },
    cloneTask(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let taskInfo = task._def.extendedProps.taskInfo;

      let info = {
        start: taskInfo.start,
        end: taskInfo.end,
        resume: taskInfo.resume,
        description: taskInfo.description,
        note: taskInfo.note,
        usersSelecteds: [{ id: parseInt(task._def.extendedProps.userId) }],
      };
      if (taskInfo.type === 1 || taskInfo.type === 2) {
        //booking or unavaliable
        info.date = taskInfo.date;
        info.type = parseInt(taskInfo.type);
        return this.addBooking(info);
      } else {
        //task
        info.pat_number = parseInt(taskInfo.pat_number);
        info.type = taskInfo.typeId;
        info.from_support = true;
        return this.saveNewTask(info);
      }
    },
  },
};
</script>

<style>
.f-event-main {
  background: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border: 0px !important;
  overflow: hidden;
}

.fc-event-title {
  font-weight: bold !important;
}

.position-fixed {
  position: fixed;
}

.fc .fc-view-harness td {
  background: rgba(0, 0, 0, 0);
}
.pi-chevron-down::before {
  color: #6c757d !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #fff;
  color: #000;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #ced4da;
  background: #fff;
}
.p-tree-container .pi-check:before {
  color: black;
}
.p-tree-container .pi-user:before {
  color: #6c757d;
}
.p-tree-container .pi-home:before {
  color: #6c757d;
}
.p-tree-container .pi-chevron-right:before {
  color: #6c757d;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-tooltip {
  z-index: 99;
}
.p-tooltip-text {
  background-color: #fff !important;
  color: #000 !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #000 !important;
}
.cursor-grab {
  cursor: grab;
}

.fc-timeGridWeek-button {
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.fc-button-active {
  background-color: #97c1fe !important;
  border-color: #97c1fe !important;
}

.slotLaneStriped {
  background-color: rgba(239, 239, 239, 0.25) !important;
}

.tree-border-color {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.square {
  display: inline-flex;
  width: 10px !important;
  height: 10px !important;
  margin-right: 5px;
  margin-top: 4px;
}
</style>
